:root{
    --primary: #173039;
    --light_grey: #F2F2F2;
    --grey: #808080;
    --grey2: #DDDDDD;
    --logoHeight: auto;
    --logoWidth: 250px;
    --accentColor: #46db83;
    --navy: #173039;
    --light_navy: #46db83;
		--black1: rgba(0, 0, 0, 0.87);
		--white:#FFFFFF;
    --black2: #393939;
		--skyblue:#4FAADB;
    --red:#E04D43;
    --yellow:#FCF200;
    --green:#76D33E;
    --grad1: #46db83;
    --grad2: #46db83;
        /*TAKE SAME COLOR AS LIGHT_NAVY AND CONVERT https://www.rapidtables.com/convert/color/hex-to-rgb.html */
    --shadowColor: 79, 170, 219;

    --logoHeight: auto;
    --logoWidth: 250px;

     /*TAKE SAME COLOR AS PRIMARY AND CONVERT https://angel-rs.github.io/css-color-filter-generator/ */
    --primaryfilter: brightness(0) saturate(100%) invert(13%) sepia(18%) saturate(1476%) hue-rotate(150deg) brightness(97%) contrast(90%);
}
